
import { defineComponent, ref, UnwrapRef, reactive, onMounted } from 'vue'
import { HomeOutlined, SaveOutlined } from '@ant-design/icons-vue'
import { notification } from 'ant-design-vue'

import validate from '../../../services/validator'
import keyService from '../../../services/key'

interface KeyFormState {
  _id?: string;
  type: string;
  key: string;
  secret: string;
}
export default defineComponent({
  components: {
    HomeOutlined,
    SaveOutlined
  },
  setup () {
    const sloading = ref<boolean>(false)
    const ploading = ref<boolean>(false)
    const stripeFormRef = ref()
    const payPalFormRef = ref()
    const keys = ref([])
    const keyRules = validate.paymentKeyValidation
    const stripeForm: UnwrapRef<KeyFormState> = reactive({
      type: 'Stripe',
      key: '',
      secret: ''
    })
    const payPalForm: UnwrapRef<KeyFormState> = reactive({
      type: 'Paypal',
      key: '',
      secret: ''
    })
    const notify = async (message: string, description: string, type: string) => {
      notification[type]({
        message,
        description
      })
    }
    const getKeys = async () => {
      try {
        const responce = await keyService.getKeys()
        keys.value = responce.data
        const stripeKey = responce.data.filter(key => key.type === 'Stripe')
        const paypalKey = responce.data.filter(key => key.type === 'Paypal')
        Object.assign(stripeForm, stripeKey[0])
        Object.assign(payPalForm, paypalKey[0])
      } catch {
        notify('Error', 'Something went wrong', 'error')
      }
    }
    const updateKey = async (id, data: KeyFormState) => {
      try {
        await keyService.updateKey(id, data)
        notify('Success', 'Key updated successfully', 'success')
        ploading.value = false
        sloading.value = false
      } catch {
        notify('Error', 'Something went wrong', 'error')
        ploading.value = false
        sloading.value = false
      }
    }
    const updateFormValue = async (type) => {
      if (type === 'Paypal') {
        ploading.value = true
        updateKey(payPalForm._id, payPalForm)
      }
      if (type === 'Stripe') {
        sloading.value = true
        updateKey(stripeForm._id, stripeForm)
      }
    }
    onMounted(() => {
      getKeys()
    })
    return {
      stripeFormRef,
      payPalFormRef,
      stripeForm,
      payPalForm,
      keyRules,
      sloading,
      ploading,
      updateFormValue
    }
  }
})
