<template>
  <div class="main">
    <div class="key content-body">
      <a-breadcrumb>
        <a-breadcrumb-item href="">
          <HomeOutlined />
        </a-breadcrumb-item>
        <a-breadcrumb-item href="">
          <span>Dashboard</span>
        </a-breadcrumb-item>
        <a-breadcrumb-item> Settings </a-breadcrumb-item>
        <a-breadcrumb-item> Keys </a-breadcrumb-item>
      </a-breadcrumb>
      <a-row class="body">
        <a-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="payment-type">
          <div class="header">Stripe keys</div>
          <div class="body">
            <a-form ref="stripeFormRef" :model="stripeForm" :rules="keyRules" layout="vertical" @finish="updateFormValue('Stripe')">
              <a-row>
                <a-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" class="key">
                  <a-form-item label="Stripe Key" required has-feedback name="key">
                    <a-input v-model:value="stripeForm.key" type="text" size="large" placeholder="Enter Key" autocomplete="off" />
                  </a-form-item>
                </a-col>
                <a-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" class="secret">
                  <a-form-item label="Stripe Secret" required has-feedback name="secret">
                    <a-input v-model:value="stripeForm.secret" type="text" size="large" placeholder="Enter Secret" autocomplete="off" />
                  </a-form-item>
                  <a-form-item :wrapper-col="{ span: 24, offset: 10 }">
                    <a-button type="primary" ghost html-type="submit" :loading="sloading">
                      <SaveOutlined /> Update
                    </a-button>
                  </a-form-item>
                </a-col>
              </a-row>
            </a-form>
          </div>
        </a-col>
        <a-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="payment-type">
          <div class="header">Paypal keys</div>
          <div class="body">
            <a-form ref="payPalFormRef" :model="payPalForm" :rules="keyRules" layout="vertical" @finish="updateFormValue('Paypal')">
              <a-row>
                <a-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" class="key">
                  <a-form-item label="Paypal Client Id" required has-feedback name="key">
                    <a-input v-model:value="payPalForm.key" type="text" size="large" placeholder="Enter Key" autocomplete="off" />
                  </a-form-item>
                </a-col>
                <a-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" class="secret">
                  <a-form-item label="Paypal Client Secret" required has-feedback name="secret">
                    <a-input v-model:value="payPalForm.secret" type="text" size="large" placeholder="Enter Secret" autocomplete="off" />
                  </a-form-item>
                  <a-form-item :wrapper-col="{ span: 24, offset: 10 }">
                    <a-button type="primary" ghost html-type="submit" :loading="ploading">
                      <SaveOutlined /> Update
                    </a-button>
                  </a-form-item>
                </a-col>
              </a-row>
            </a-form>
          </div>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, UnwrapRef, reactive, onMounted } from 'vue'
import { HomeOutlined, SaveOutlined } from '@ant-design/icons-vue'
import { notification } from 'ant-design-vue'

import validate from '../../../services/validator'
import keyService from '../../../services/key'

interface KeyFormState {
  _id?: string;
  type: string;
  key: string;
  secret: string;
}
export default defineComponent({
  components: {
    HomeOutlined,
    SaveOutlined
  },
  setup () {
    const sloading = ref<boolean>(false)
    const ploading = ref<boolean>(false)
    const stripeFormRef = ref()
    const payPalFormRef = ref()
    const keys = ref([])
    const keyRules = validate.paymentKeyValidation
    const stripeForm: UnwrapRef<KeyFormState> = reactive({
      type: 'Stripe',
      key: '',
      secret: ''
    })
    const payPalForm: UnwrapRef<KeyFormState> = reactive({
      type: 'Paypal',
      key: '',
      secret: ''
    })
    const notify = async (message: string, description: string, type: string) => {
      notification[type]({
        message,
        description
      })
    }
    const getKeys = async () => {
      try {
        const responce = await keyService.getKeys()
        keys.value = responce.data
        const stripeKey = responce.data.filter(key => key.type === 'Stripe')
        const paypalKey = responce.data.filter(key => key.type === 'Paypal')
        Object.assign(stripeForm, stripeKey[0])
        Object.assign(payPalForm, paypalKey[0])
      } catch {
        notify('Error', 'Something went wrong', 'error')
      }
    }
    const updateKey = async (id, data: KeyFormState) => {
      try {
        await keyService.updateKey(id, data)
        notify('Success', 'Key updated successfully', 'success')
        ploading.value = false
        sloading.value = false
      } catch {
        notify('Error', 'Something went wrong', 'error')
        ploading.value = false
        sloading.value = false
      }
    }
    const updateFormValue = async (type) => {
      if (type === 'Paypal') {
        ploading.value = true
        updateKey(payPalForm._id, payPalForm)
      }
      if (type === 'Stripe') {
        sloading.value = true
        updateKey(stripeForm._id, stripeForm)
      }
    }
    onMounted(() => {
      getKeys()
    })
    return {
      stripeFormRef,
      payPalFormRef,
      stripeForm,
      payPalForm,
      keyRules,
      sloading,
      ploading,
      updateFormValue
    }
  }
})
</script>

<style lang="scss">
.key {
  .body {
    .payment-type {
      background: #fff;
      padding: 15px;
      margin: 20px 10px 10px;
      border-radius: 10px;
      min-height: 200px;
      .header {
        text-align: left;
        font-family: 'TT Norms Pro Medium';
        font-size: 16px;
        margin-bottom: 10px;
      }
      .body {
        .ant-form {
          input {
            font-size: 14px;
          }
          .key {
            padding-right: 5px;
          }
          .secret {
            padding-left: 5px;
          }
          .has-error {
            .ant-form-explain {
              text-align: left;
            }
          }
        }
      }
    }
  }
}
</style>
